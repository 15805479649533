import React from "react"
import Separator from "./separator"

import * as menuStyles from "./menu.module.css"

const Menu = ({ title, children }) => (
  <div className={menuStyles.parent}>
    <h1>
      <Separator content={title} />
    </h1>
    {children}
  </div>
)

export default Menu
