import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Menu from "../components/menu"
import MenuSection from "../components/menuSection"

const Balloons = () => (
  <Layout>
    <Seo title="Balloons" />
    <Banner>
      <StaticImage
        src="../images/balloons_banner.jpg"
        alt='Banner image showing a balloon arch above "Lets Pawty," black and gold balloons, and a birthday party balloon garland'
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
    <Menu title="BALLOONS">
      <MenuSection>
        <ul>
          <li>Bouquets</li>
          <li>Garlands</li>
          <li>Columns &amp; Towers</li>
          <li>Centerpieces</li>
          <li>Jumbo Letters &amp; Numbers</li>
          <li>Gender Reveal</li>
          <li>Pop Drops</li>
          <li>Confetti-filled</li>
        </ul>
      </MenuSection>
    </Menu>
  </Layout>
)

export default Balloons
