import React from "react"

import * as menuSectionStyles from "./menuSection.module.css"

const MenuSection = ({ title, children }) => (
  <div className={menuSectionStyles.parent}>
    <h2>{title}</h2>
    {children}
  </div>
)

export default MenuSection
